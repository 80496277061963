import {
  FormsFieldPreset,
  FIELD_COMPONENT_TYPES,
  FormPlugin,
  FieldCollectionType,
  BASE_DESIGN_GROUPS,
} from '@wix/forms-common'
import { FIELDS } from '../../../../../../constants/roles'
import { iconNames } from '../../icons/types'
import { IGeneralField } from '../../general-field'
import { CATEGORIES } from '../../../../../../panels/manage-fields-panel/constants/manage-fields-constants'
import { fieldManifest } from '../../field-utils'
import { baseDesignMappingValues } from '../../constants'
import { CRM_TYPES } from '../../../../../../constants/crm-types-tags'
import { FieldRenderConfig } from '../../field-types-data'
import { ServerFieldType } from '../../../../../../constants/field-types'
import { FieldDataType } from '@wix/ambassador-contacts-fields-app/types'

export class GeneralDatePicker extends IGeneralField {
  public get customFields() {
    return [FieldDataType.DATE]
  }

  public get mainCrmTypes() {
    return this.enableFieldMigration() ? [CRM_TYPES.BIRTHDATE] : [CRM_TYPES.DATE]
  }

  protected get icon() {
    return iconNames.date
  }

  public get collectionFieldType() {
    return FieldCollectionType.TEXT
  }

  protected get componentType() {
    return FIELD_COMPONENT_TYPES.DATE_PICKER
  }

  public get role() {
    return FIELDS.ROLE_FIELD_DATE
  }

  protected get renderConfig(): Partial<FieldRenderConfig> {
    return {
      [FormPlugin.FORM_BUILDER]: {
        addFieldPanelData: {
          category: CATEGORIES.date,
          subCategory: CATEGORIES.basic,
        },
      },
    }
  }

  public get fieldType(): FieldPreset {
    return FormsFieldPreset.GENERAL_DATE_PICKER
  }

  public get serverType(): ServerFieldType {
    return ServerFieldType.DATE_PICKER
  }

  public get base() {
    return GeneralDatePicker.prototype
  }

  public get manifest() {
    return fieldManifest({
      displayName: this.translate('fields.labelName.date'),
      duplicatable: true,
    })
  }

  public get supportsLabel() {
    return true
  }

  public get supportedInResponsive() {
    return true
  }

  protected get skin() {
    return 'wysiwyg.viewer.skins.input.DatePickerTextBetweenNavSkin'
  }

  protected get layout() {
    return {
      width: 240,
      height: 42,
    }
  }

  protected get props() {
    return {
      type: 'DatePickerProperties',
      required: false,
      textAlignment: 'left',
      textPadding: 12,
      dateFormat: 'MM/DD/YYYY',
      defaultTextType: 'placeholder',
    }
  }

  protected get data(): any {
    return {
      type: 'DatePicker',
      placeholder: this.translate(`fieldTypes.${this.fieldType}.placeholderText`),
      disabledDates: [],
      disabledDaysOfWeek: [],
      allowPastDates: true,
      allowFutureDates: true,
    }
  }

  public get designMapping() {
    return {
      [this.componentType]: {
        ...baseDesignMappingValues,
        [BASE_DESIGN_GROUPS.INPUT_BACKGROUND]: ['bg', 'bg-calendar', 'bg-header'],
        [BASE_DESIGN_GROUPS.INPUT_BORDER_COLOR]: ['brd', 'brd-calendar', 'bg-day-selected'],
        [BASE_DESIGN_GROUPS.PLACEHOLDER_TEXT_COLOR]: [
          'txt-placeholder',
          'icn-color',
          'txt-day',
          'txt-header',
        ],
        [BASE_DESIGN_GROUPS.LABEL_REQUIRED_TEXT_COLOR]: ['txtlblrq'],
      },
    }
  }

  public get layoutComponentProps() {
    return ['textAlignment', 'textPadding', 'labelPadding', 'labelMargin']
  }

  protected get styleProperties() {
    return {
      properties: {
        'alpha-bg': '1',
        'alpha-bgh': '1',
        'alpha-brd': '0.55',
        'alpha-brdf': '1',
        'alpha-brdh': '0.9',
        'alpha-icn-color': '0.5',
        bg: 'color_11',
        'bg-calendar': 'color_11',
        'bg-day-selected': 'color_18',
        'bg-header': 'color_11',
        bgf: 'color_11',
        bgh: 'color_11',
        'boxShadowToggleOn-shd': 'false',
        'boxShadowToggleOn-shd-calendar': 'false',
        brd: 'color_15',
        'brd-calendar': 'color_15',
        brdf: 'color_18',
        brdh: 'color_15',
        brw: '1',
        'brw-calendar': '1',
        brwf: '1',
        brwh: '1',
        fnt: 'font_8',
        'fnt-family-day': 'helvetica-w01-light',
        'fnt-family-header': 'helvetica-w01-light',
        'icn-color': 'color_15',
        rd: '0px',
        'rd-calendar': '0px',
        'rd-day-selected': '30px',
        shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
        'shd-calendar': '0.00px 0.00px 15px 2px rgba(0,0,0,0.06)',
        txt: 'color_15',
        'txt-day': 'color_15',
        'txt-day-selected': 'color_11',
        'txt-header': 'color_15',
        'txt-placeholder': 'color_14',
        'txt-size-day': '14',
        'txt-size-header': '16',
      },
      propertiesSource: {
        'alpha-bgh': 'value',
        'alpha-brd': 'value',
        'alpha-brdf': 'value',
        'alpha-brdh': 'value',
        'alpha-icn-color': 'value',
        bg: 'theme',
        'bg-calendar': 'theme',
        'bg-day-selected': 'theme',
        'bg-header': 'theme',
        bgcd: 'value',
        bgf: 'theme',
        bgh: 'theme',
        brd: 'theme',
        'brd-calendar': 'theme',
        brdf: 'theme',
        brdh: 'theme',
        brw: 'value',
        'brw-calendar': 'value',
        brwf: 'value',
        brwh: 'value',
        fnt: 'theme',
        'fnt-family-day': 'value',
        'fnt-family-header': 'value',
        'icn-color': 'theme',
        rd: 'value',
        'rd-calendar': 'value',
        'rd-day-selected': 'value',
        shd: 'value',
        'shd-calendar': 'value',
        txt: 'theme',
        'txt-day': 'theme',
        'txt-day-selected': 'theme',
        'txt-header': 'theme',
        'txt-placeholder': 'theme',
        'txt-size-day': 'value',
        'txt-size-header': 'value',
      },
    }
  }
}
